import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';

const BlogIndex = props => {
  const { data, location } = props;

  let lang = 'en';

  if (typeof window !== 'undefined') {
    lang = localStorage.getItem('selectedLanguage');
  }

  const posts = lang == 'fr' ? data.blogFr.edges : data.blog.edges;

  const filteredPosts = posts.filter(post => {
    const { node } = post;

    return node.image.fluid;
  });

  const featuredPost = filteredPosts[0].node;

  return (
    <Layout location={location}>
      <div className="blog-page" role="main">
        <Link style={{ boxShadow: 'none' }} to={`/${featuredPost.slug}`}>
          <div className="featured-blog-card" key={featuredPost.slug}>
            <div className="header-image flip">
              <img
                className="scale flip"
                src={featuredPost.image.fluid.src}
                alt={featuredPost.image.title}
              />
            </div>
            <div className="featured-blog-text">
              <h3>{featuredPost.title}</h3>
              <p>{featuredPost.publishedAt}</p>
              <p>{featuredPost.description}</p>
            </div>
          </div>
        </Link>
        <div className="blog-list">
          {filteredPosts.slice(1).map(({ node }) => (
            <Link
              style={{ boxShadow: 'none' }}
              key={node.slug}
              to={`/${node.slug}`}
              replace
            >
              <div className="blog-card">
                <div className="blog-card-image">
                  <img src={node.image.fluid.src} alt={node.image.title} />
                </div>
                <div>
                  <h3>
                    {node.title.length > 60
                      ? `${node.title.substring(0, 60)}...`
                      : node.title}
                  </h3>
                  <p>{node.publishedAt}</p>
                  <p>
                    {node.description.length > 100
                      ? `${node.description.substring(0, 100)}...`
                      : node.description}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default BlogIndex;

BlogIndex.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query {
    blog: allContentfulPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { isFrench: { ne: true } }
    ) {
      edges {
        node {
          title
          description
          publishedAt(formatString: "DD/MM/YYYY")
          slug
          image {
            title
            fluid(maxWidth: 1800) {
              src
            }
          }
        }
      }
    }
    blogFr: allContentfulPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { isFrench: { eq: true } }
    ) {
      edges {
        node {
          title
          description
          publishedAt(formatString: "DD/MM/YYYY")
          slug
          image {
            title
            fluid(maxWidth: 1800) {
              src
            }
          }
        }
      }
    }
  }
`;
